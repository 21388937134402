const rawColor = {
  darkRed: "#410f1f",
  darkRedOverlay: "#663544",
  goldenRod: "#deb534",
  white: "#ffffff",
};
const localColors = {
  backgroundBridesMaid: rawColor.white,
  backgroundContent: rawColor.darkRed,
  backgroundGroomsMan: rawColor.darkRedOverlay,
  backgroundTitle: rawColor.white,
  backgroundToolbar: rawColor.darkRedOverlay,
  fontColorBridesMaid: rawColor.darkRedOverlay,
  fontColorGroomsMan: rawColor.white,
  fontColorTitle: rawColor.goldenRod,
  rawColor: rawColor,
  textColor: rawColor.white,
};

export default localColors;
