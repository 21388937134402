import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import localColors from "../util/Colors";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: localColors.backgroundTitle,
  },
  title: {
    textAlign: "center",
    flexGrow: 1,
    cursor: "pointer",
    // fontFamily: "/Snell\ Roundhand\ Script.tff",
    color: localColors.fontColorTitle,
  },
  subtitle: {
    textAlign: "center",
    flexGrow: 1,
  },
}));

export default function Title(props) {
  const { action, title, subtitle } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h2" className={classes.title} onClick={action}>
        {title}
      </Typography>

      <div style={{ paddingBottom: "10px" }} />
      <Typography variant="h5" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
}
