import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { getMemorialPhotos } from "./data/DAO";
import Title from "./components/Title";
import { Centered } from "./util/Styled";
import Gallery from "./components/Gallery";

function changePage(pageType) {
  window.pageContent.changePage(pageType);
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    display: "block",
    backgroundColor: "#D6EEC4",
  },
  title: {
    flexGrow: 1,
    padding: "2px",
    color: "white",
  },
}));

const Header = () => {
  return (
    <Centered>
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <div
          style={{
            width: "80%",
            textAlign: "start",
          }}
        >
          <p
            style={{
              color: "black",
              padding: "20px",
            }}
          >
            In memory of Daniel Beagan, of Cranston, Rhode Island who passed
            away on Christmas Eve 2020 at the age of 95. Married to his late
            wife Fran for over 65 years. Father to Dan, Jim, John, Kevin and
            Marianne. Grandfather, uncle, godfather and friend to so many
            others.
            <br />
            <br />
            Dan grew up in a family in Providence, RI. He served in the Navy in
            World War II, worked for 41 years at Gorham Silverware and spent his
            retirement performing with the Cranston Silvertones and his own
            singing group at senior centers and parties around Rhode Island.
            These pictures serve as snapshots into all the joy he brought to
            people during his life.
          </p>
        </div>
      </div>
    </Centered>
  );
};
const Footer = () => {
  return (
    <Centered>
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <div
          style={{
            width: "80%",
            color: "black",
            padding: "20px",
          }}
        >
          <p style={{ color: "black" }}>
            {" "}
            Dan fit a lot of life in his 95 years. He will be remembered by all
            who heard him sing and were touched by him.
          </p>
          <p style={{ color: "black" }}>
            Click{" "}
            <a href="https://drive.google.com/drive/folders/1q-P233MZzqP3DqIhPmZYnlZd21yY74vm?usp=sharing">
            here
            </a>
            {" "}to download a zip file of all photos.
          </p>
        </div>
      </div>
    </Centered>
  );
};

function MemorialWebsite() {
  const classes = useStyles();
  return (
    <div
      className="App"
      style={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "#D6EEC4",
      }}
    >
      <Title title={"Dan Beagan"} subtitle={"1925 - 2020"} />
      <div className={classes.root}>
        <Header />
      </div>
      <Gallery photoset={getMemorialPhotos()} />
      <div className={classes.root}>
        <Footer />
      </div>
    </div>
  );
}

function App() {
  return (
    <div>
      <MemorialWebsite />
    </div>
  );
}

export default App;
