import React from "react";
import "../modal.css";
import { Grid } from "@material-ui/core";
import spans from "../util/LocalSpans";

export default class ModalImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  render() {
    this.captionText = <div id="caption"></div>;

    const modalStyle = {};
    modalStyle.display = this.state.isModalOpen ? "block" : "none";

    return (
      <Grid
        style={{
          padding: "20px",
        }}
        item
        xs={spans.halfwidth}
        sm={spans.thirdwidth}
        md={spans.quarterwidth}
      >
        <img
          style={{
            maxWidth: "200px",
            width: "100%",
            height: "100%",
            maxHeight: "200px",
            border: "solid white 1px",
          }}
          onClick={() => {
            this.setState({ isModalOpen: true });
          }}
          src={this.props.thumb}
        />
        <div id="myModal" className="modal" style={modalStyle}>
          <span
            className="close"
            onClick={() => {
              this.setState({ isModalOpen: false });
            }}
          >
            &times;
          </span>{" "}
          <img
            className="modal-content"
            id="img01"
            src={this.state.isModalOpen ? this.props.src : ""}
          ></img>{" "}
          {this.captionText}
        </div>
      </Grid>
    );
  }
}
